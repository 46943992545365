/* team.css */

.team-card {
  overflow: hidden;
  margin: 10px;
  padding: 10px;
  width: calc((100% - 60px) / 4); 
  min-width: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid black;
}

.image-cropper {
  width: 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  background-color: black;
}
.profile-pic {
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
}

.card-content {
  margin-top: 10px;
  margin-bottom: 50px;
}

.card-content h3 {
  margin: 0;
}

.card-content p {
  margin: 5px 0;
  font-size: calc(12px + 0.4vw) !important;
}

.card-content a {
  font-size: calc(12px + 0.4vw) !important;
}

.p-bigger {
  font-weight: bold;
}


