.research-page {
  margin: 0px 15vw 20px 15vw;
  padding-bottom: 30px;
  border-bottom: 1px solid black;
}

.research-page-contents {
  margin: 15px 15vw 70px 15vw;
}

.research-page p {
  font-weight: bold;
}

.flex-row {
  display: flex;
  gap: 50px;
  align-items: center;
}

.research-container {
  padding: 20px 60px 40px 60px;
  justify-content: center;
  border-bottom: 1px solid black;
}
.research-container-last {
  padding: 20px 60px 40px 60px;
  justify-content: center;
}

.boxes-row {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.box {
  flex: 0 1 calc(30% - 20px); /* Adjust the width of the boxes as needed */
  margin-right: 20px; /* Adjust the spacing between boxes */
  margin-left: 20px; /* Adjust the spacing between boxes */
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.box:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.752);
  -webkit-transition: none;
    -moz-transition: none;
          transition: none;
}

.box:hover:after {
  width: 120%;
  background-color: rgba(255,255,255,0);
  
  -webkit-transition: all 0.4s ease-in-out;
     -moz-transition: all 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;
}
.box h2 {
  font-size: calc(16px + 0.4vw);
  margin-bottom: 10px;
}


.research-header h3 {
  color: #8D181B;
  text-transform: uppercase;
}

.research-image img {
  max-height: 50vh;
  padding: 30px;
}

.text-box {
  text-align: left;
  margin-left: 20px;
}

@keyframes fadeDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.research-header h3 {
  font-size: calc(16px + 1vw);
}
.box {
  opacity: 0; /* Initially hide the boxes */
  background-color: #8d181c2c;
}

.box.fade-in {
  animation: fadeDown 1.5s ease forwards;
}

.box:hover { 
  transform: scale(1.05) !important;  
  cursor: pointer;
}


@media screen and (max-width: 1200px) {
  .research-image img {
    max-width: 100%; 
  }

  .text-box {
    margin-left: 0; /* Reset the margin for smaller screens */
  }

  .research-description li {
    font-size: calc(10px + 0.4vw);
  }

  .left-padding {
    padding-left: 0px !important;
  }
  .boxes-row {
    flex-direction: column; /* Stack boxes vertically */
  }
  
  .box {
    flex: 1 1 100%; /* Boxes take full width */
    margin-right: 0; /* Remove margin between boxes */
    margin-bottom: 20px; /* Add margin between boxes vertically */
  }
  
}
