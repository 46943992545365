
.publications-container {
    margin: 0px 15vw 150px 15vw;
}

.featured-publications-container {
    margin: 175px 8vw 10px 8vw;
    min-height: 75vh;
}

.publications-list p a {
    text-decoration: none;
    color: #4469ad;
}

.publications-list {
    border-top: 1px solid black;
    padding-top: 50px;
    padding-bottom: 30px;
}

.publications-list p {
  font-size: calc(10px + 0.4vw);
}

@keyframes fadeDown {
  from {
      opacity: 0;
      transform: translateY(-50px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.custom-card.fade-in {
  animation: fadeDown 1.5s ease forwards;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0px -10px; /* Adjust margin to handle spacing */
  }

  .card {
    flex: 0 0 calc(33.333% - 20px); /* Three cards per row on laptops */
    width: calc(33.333% - 20px); /* Adjust width for laptops */
    margin-bottom: 20px;
    box-sizing: border-box; /* Ensure padding and border are included in width */
    padding: 20px 10px; /* Add padding to handle spacing */
    border: 1px solid #ddd; /* Add border */
    box-shadow: rgba(50, 77, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; /* Add soft shadow */
    border-radius: 10px; /* Add rounded corners */
    opacity:0;
}
  
  .card-body {
    /* Allow the card body to grow to fill available space */
    border-top: 1px solid rgb(218, 218, 218);
    flex: 1 1 auto;
    padding: 0.7rem;
    margin: 10px;
  }
  
  .card-title {
    font-weight: bold;
    font-family: "IBM Plex Sans", sans-serif;
    margin-bottom: 0.75rem;
  }

  .card-title a {
    color: #6e9fc1;
    font-size: calc(16px + 0.4vw);
  }
  
  .card-text:last-child {
    margin-bottom: 0;
  }

  .card-img-wrapper {
    width: 100%;
    padding-top: 50%; 
    position: relative;
    overflow: hidden;
  }
  
  .card-img-wrapper img {
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    max-width: 100%; 
    max-height: 100%;
  }
  
  .card-img-top {
    width: 100%;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  
  .card-header {
    /* Style for card header */
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .card-footer {
    /* Style for card footer */
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .card-group > .card {
    /* Add margin between cards in a card group */
    margin-bottom: 15px;
  }

  .card-text {
    font-size: calc(12px + 0.4vw);
}

.card-body .btn-pdf {
    font-size: 30px;
    color: #6e9fc1; /* Text color */
}

.card-body .btn-pdf:hover {
    color: #5a7a96; /* Text color on hover */
}

.expand-button,
.collapse-button {
    background-color: transparent;
    border: none;
    color: #8D181B;
    font-size: calc(11px + 0.4vw);
    padding: 5px; /* Remove default padding */
    margin-left: 5px; /* Add some space between buttons */
    cursor: pointer; /* Show pointer on hover */
}

.expand-button:hover,
.collapse-button:hover {
    color: #d19d34; /* Hover color */
}
  
  @media (max-width: 1200px) {
    .card {
        flex: 0 0 100%; /* One card per row on small devices */
        width: 100%; /* Adjust width for small devices */
    }
}

/* Modal Overlay */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.809); /* semi-transparent black */
    z-index: 1040; /* modal backdrop z-index */
}

/* Modal Container */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1050; /* modal container z-index */
}

/* Modal Dialog */
.modal-dialog {
    max-width: 40vw; /* max width of the modal dialog */
    margin: 0 auto;
}

@media (max-width: 1200px) {
    .modal-dialog {
        min-width: 80vw;
    }

    .modal-body {
        font-size: calc(10px + 0.4vw);
    }

    @keyframes fadeDown {
      from {
          opacity: 0;
          transform: translateY(-20px);
      }
      to {
          opacity: 1;
          transform: translateY(0);
      }
    }
    
}

@media (max-width: 280px) {
    .modal-dialog {
        min-width: 90vw;
    }

    .modal-body {
        font-size: calc(6px + 0.4vw);
    }
}

/* Modal Content */
.modal-content {
    background-color: #fff; /* modal content background color */
    border: 1px solid rgba(0, 0, 0, 0.2); /* modal content border */
    border-radius: 0.3rem; /* modal content border radius */
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5); /* modal content shadow */
}

/* Modal Header */
.modal-header {
    padding: 35px 25px; 
    border-bottom: 1px solid #dee2e6; /* modal header border */
}

/* Modal Body */
.modal-body {
    padding: 25px;
}

/* Modal Footer */
.modal-footer {
    padding: 25px;
    border-top: 1px solid #dee2e6; /* modal footer border */
}

.btn-close {
    display: none;
}

.btn-secondary {
    background-color: transparent;
    cursor: pointer;
    height: auto;
    padding: 10px;
    margin-right: 10px;
    border-radius: 7px;
    border: 2px solid black;
  }
  
  .btn-secondary:hover {
    color: #fff;
    background-color: #8D181B;
  }
  
  .btn-secondary:active {
    box-shadow: none;
  }

  .button-div {
    display: flex;
    align-items: center;
    bottom: 0;
}