.banner-container-join-us {
    height: 75vh;
    background-size: 100%;
    margin-right: 50px;
    margin-left: 50px;
    margin-top: -20px;
    background: url('../images/join-us-photo.png') no-repeat center;
    background-size: 120%; 
    transition: background-size 0.3s ease;
  }
  
  .contact-container {
    margin: 50px 15vw 150px 15vw;
  }

  .contact-container p a {
    color: #6e9fc1
  }

  @media only screen and (max-width: 600px) {
    .banner-container-join-us {
        margin-top: -100px;
      }
  }

  @media only screen and (max-width: 500px) {
    .banner-container-join-us {
        margin-top: -50px;
        height: 50vh;
        background-size: 200%; 
      }
  }

  .small-icon {
    width: 50px;
  }