.banner-container {
    height: 75vh;
    background-color: blue;
    background-size: 100%;
    margin-right: 50px;
    margin-left: 50px;
    margin-top: -20px;
    background: url('https://wallpapercave.com/wp/wp2017235.jpg') no-repeat center;
    background-size: 170%; 
    transition: background-size 0.3s ease;
  }
  
  .contact-container {
    margin: 50px 15vw 150px 15vw;
  }

  .location, .contacts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .location-col {
    flex: 2;
    margin: 50px;
  }

  .location-info-col {
    flex: 1;
    margin: 50px;
  }

  .borders {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }

  .contact-person {
    flex: 1;
    padding: 0px 50px 50px 50px;
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
    .banner-container {
        margin-top: -100px;
      }
  }

  @media only screen and (max-width: 500px) {
    .contacts {
      margin-bottom: 50px;
    }
    .banner-container {
        margin-top: -50px;
        height: 50vh;
        background-size: 400%; 
      }
      .contact-person {
        flex: 1;
        padding: 20px;
        text-align: center;
      }
      .location-info-col {
        flex: 1;
        margin: 0px;
        margin-bottom: 50px;
      }
      .contact-container {
        margin: 50px 15vw 50px 15vw;
      }
      
  }